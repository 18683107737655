import Vue from "vue";
import App from "./App.vue";
import router from "./router/";
import axios from "axios";
import VueImg from "v-img";
import VueHorizontal from "vue-horizontal";
import VueMeta from "vue-meta";

Vue.use(VueImg);
Vue.use(VueHorizontal);
Vue.use(VueMeta, {
    tagIDKeyName: "hid",
    keyName: "head",
});

Vue.config.productionTip = false;

Vue.use({
    install(Vue) {
        Vue.prototype.$api = axios.create({
            baseURL: "https://admin.itsyourdayofficial.com/api/",
        });
    },
});

new Vue({
    router,
    render: (h) => h(App),
    metaInfo: {
        // Default title template
        title: "Wedding Invitation of Dhini & Aufar",
        titleTemplate: "%s | Wedding Invitation",
        meta: [
            {
                hid: "og:url",
                name: "og:url",
                content: "https://dhiniaufar.itsyourdayofficial.com/invitation",
            },
            {   hid: "og:type", 
                name: "og:type", 
                content: "website" },
            {
                hid: "og:title",
                name: "og:title",
                content: "Wedding Invitation of Dhini & Aufar",
            },
            {   hid: "og:description", 
                name: "og:description", 
                content: "test" },
            {
                hid: "og:image",
                property: "og:image",
                content:
                    "https://admin.itsyourdayofficial.com/foto_cover/1713762105a4WTEQIV83.jpg",
            },
            { name: "twitter:card", content: "summary_large_image" },
            {
                property: "twitter:domain",
                content: "dhiniaufar.itsyourdayofficial.com",
            },
            {
                property: "twitter:url",
                content: "https://dhiniaufar.itsyourdayofficial.com/invitation",
            },
            {
                name: "twitter:title",
                content: "Wedding Invitation of Dhini & Aufar",
            },
            { name: "twitter:description", content: "" },
            {
                name: "twitter:image",
                content:
                    "https://admin.itsyourdayofficial.com/foto_cover/1713762105a4WTEQIV83.jpg",
            },
        ],
        link: [
            {
                rel: "icon",
                href: "https://admin.itsyourdayofficial.com/foto_cover/1713762105a4WTEQIV83.jpg",
            },
        ],
    },
}).$mount("#app");
